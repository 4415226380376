<template>
    <Navbar />
    <div class="body">        
        <div class="container">
            <div class="login-container">
                <div class="login-content">
                    <div class="title mb-4">FORGET PASSWORD</div>
                    <div class="input-container md:w-3/4 mx-auto">
                        <input type="email" placeholder="Email" id="forget-password" name="forget-password">
                    </div>
                    <div class="mt-12 mb-6 login-button">                        
                        <Button type="submit" title="Send" />
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <Footer />
</template>

<script>

import Navbar from '@/components/navbar/navbar.component.vue'
import Footer from '@/components/footer/footer.component.vue'
import Button from '@/components/button/button.component.vue'

export default {
    components: {
        Navbar,       
        Footer,       
        Button,       
    },
    data() {
        return {
           
        };
    },
    methods: {

    },
        

    
    created() {
       

    },

    computed: {
       
    }
};
</script>
<style lang="scss" scoped>
    @import '@/assets/css/main.scss';
    @import './forget-password.styles.scss';
</style>