<template>
  <div class="body">
      <div class="container">
          <button type="button" class="back-button" @click="back_to_manage_booking"><i class="fas fa-caret-left" @click="back_to_manage_booking"></i></button>
          <div class="chat-container" ref="chatRef" @scroll="onScroll">
            <div v-for="(value, key) in messages" :key="key">
              <div class="chat-time">{{ key }}</div>
              <div class="chat-box" v-for="message in value" :key="message.id">
                  <div class="icon-box user-icon" v-if="message.user_ID">
                    <img src="@/assets/images/icons/user.png" alt="user" draggable="false" width="40" height="40">
                  </div> 
                  <div class="icon-box qers-icon" v-else>
                    <img src="@/assets/images/icons/qers.png" alt="qers" draggable="false" width="40" height="40">
                  </div>
                  <div class="message-box">
                    <div>{{ message.message }}</div>
                    <small>{{ formatDate(message.created_at) }}</small>
                  </div>
              </div>
            </div>
          </div>
          <form class="send-container" @submit.prevent="sendMessage">
            <input v-model="newMessage" placeholder="Type your message" />
            <button type="submit">Send</button>
          </form>
      </div>        
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ['booking_id'],
  data() {
    return {
      messages: [],
      newMessage: "",
      url: "",
      chatUser:"",
      currentPage: 1,
      isLoading: false,
    };
  },
  methods: {
    async fetchMessages() {
      this.url = this.HostUrl + 'messages?booking_id=' + this.$route.params.booking_id;
      const response = await axios.get(this.url);
      this.messages = response.data;
      console.log(this.messages)
   
    },
    async sendMessage() {
      this.url = this.HostUrl + 'messages';
      if (this.newMessage.trim()) {
        const message = {
          user_ID: null,
          qers_ID: this.UserID,
          booking_ID: this.$route.params.booking_id,
          message: this.newMessage,
        };
        await axios.post(this.url, message);
        this.newMessage = "";
        this.fetchMessages();
      }
    },
    formatDate(timestamp) {
      const date = new Date(timestamp);
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const period = hours >= 12 ? 'pm' : 'am';
      
      // Convert to 12-hour format
      hours = hours % 12;
      // the hour '0' should be '12'
      hours = hours ? hours : 12;
      
      return `${hours}:${minutes} ${period}`;
    },
    back_to_manage_booking(){
      this.$router.push("/manage-bookings");
    },
    async getChatUserID(){
      this.url = this.HostUrl + 'get-chat-user?booking_id=' + this.$route.params.booking_id;
      await axios.get(this.url)
        .then(response => {
          this.chatUser = response.data
          if(parseInt(this.chatUser) !== parseInt(this.UserID)){
            alert(this.$t('This chat is not belonging to you.'))
            this.$router.push("/manage-bookings");
          }
          else{
            this.fetchMessages();   
          }
        })
        .catch(error => {
            console.log(error);
        });
      const response = axios.get(this.url);
      this.chatUser = response.data;
    },
    async loadMore() {
      if (this.isLoading) return;

      this.isLoading = true;
      const response = await Chatroom.getMessages(this.booking_id, this.currentPage);
      this.isLoading = false;

      if (response.success) {
        const newMessages = response.data;

        if (newMessages.length) {
          // append new messages to the existing messages array
          this.messages = [...this.messages, ...newMessages];
          this.currentPage += 1;
        }
      }
    },
    isDateChanged(index) {
      if (index === 0) {
        return true;
      }

      const currentDate = new Date(this.messages[index].created_at).getDate();
      const prevDate = new Date(this.messages[index - 1].created_at).getDate();

      return currentDate !== prevDate;
    },
    onScroll() {
      const element = this.$refs.chatRef;
      if (element.scrollTop === 0) {
        this.loadMore();
      }
    }
  },
  mounted() {
    this.fetchMessages();
    setInterval(this.fetchMessages, 5000); // Poll every 5 seconds  
  },
  unmounted(){
      
  },
  created() {
    this.getChatUserID();
       
  },
  computed: {
    HostUrl() {
      return this.$store.state.host_url;
    },
    Username() {
      return this.$store.state.username;
    },
    UserID() {
      return this.$store.state.user_id;
    },

  }, 
  updated() {
    const chatRefDiv = this.$refs.chatRef;
    chatRefDiv.scrollTop = chatRefDiv.offsetHeight;    
  }
};
</script>

<style lang="scss" scoped>
  @import '@/assets/css/main.scss';
  @import './chat-room.styles.scss';
</style>
