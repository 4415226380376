<template>

    <div class="body">        
        <div class="container">
            <div class="login-container">
                <div class="login-content">
                    <div class="title">{{ $t('Login') }}</div>
                    <div class="input-container md:w-3/4 mx-auto">
                        <input type="text" :placeholder="$t('Username')" v-model="username">
                    </div>
                    <div class="input-container md:w-3/4 mx-auto password">
                        <input :type="passwordFieldType" :placeholder="$t('Password')" v-model="password">
                        <div class="show-password" @click="switchVisibility()"><i class="bi bi-eye-fill"></i></div>
                        <div class="remember-checkbox">
                            <input type="checkbox" id="remember">
                            <label for="remember">Remember me</label>
                        </div>
                    </div>
                    <div>{{ feedback }}</div>
                    <div class="mt-12 mb-6 login-button">                        
                        <Button type="submit" :title="$t('Login')" @click="login" />
                    </div>
                    <div class="mt-12 bottom-links">
                        <a href="/register" class="link">{{ $t('Create new account') }}</a>                        
                        <a href="/forget-password" class="link">{{ $t('Forget Password') }}</a>                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>

</template>

<script>
import store from "../../store";
import axios from 'axios';
import Button from '@/components/button/button.component.vue'
export default {
    name: "Login",
    components: {
        Button,     
    },
    data() {
        return {
            username: "",
            password: "",
            feedback: "",
            result: '',
            remember: false,
            url: '',
            passwordFieldType: 'password'
        };
    },
    methods: {
        login(){
            this.feedback = ''
            if(this.username == "") {
                this.feedback = this.$t('Please enter your username.');
                return
            }
            if(this.password == "") {
                this.feedback = this.$t('Please enter your password.')
                return
            }
            
            
            axios.post(this.url, 
                {
                    username: this.username,
                    password: this.password
                })
            .then(response => {
                this.result = response.data;
                console.log(response.data)
                console.log(this.result['status'])
                
                
                if(this.result['status'] == 200) {
                    store.commit("IsLogin", true);
                    store.commit("Username", this.username);
                    store.commit("UserID", this.result['user']['ID']);
                    if(this.remember == true) {
                        store.commit("RememberUsername", this.username)
                        store.commit("RememberPassword", this.password)
                        store.commit("RememberBoolean", true)
                    }
                    else {
                        store.commit("RememberUsername", '')
                        store.commit("RememberPassword", '')
                        store.commit("RememberBoolean", false)
                    }
                    this.$router.push("/");
                }
                else {
                    this.feedback = this.result
                }
                
                
            })    
            .catch(function (error) {
                console.log(JSON.stringify(error));
                console.log("error response :" + error.response)
                console.log("error status :" + error.status)
            });
            
           
        },
        switchVisibility() {
            console.log("switch visibility")
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
        },
    },
        
    
    created() {
        store.commit("HostUrl", 'https://backend.narabuq.com/api/')
        this.url = this.HostUrl + "login"
        this.remember = this.RememberBoolean
        if(this.remember){
            this.username = this.RememberUsername
            this.password = this.RememberPassword
        }
       
    },
    computed: {
        HostUrl() {
            return this.$store.state.host_url;
        },
        
        RememberBoolean(){
            return this.$store.state.remember_boolean;
        },
        RememberUsername(){
            return this.$store.state.remember_username;
        },
        RememberPassword(){
            return this.$store.state.remember_password;
        }
    }
};
</script>
<style lang="scss" scoped>
    @import '@/assets/css/main.scss';
    @import './login.styles.scss';
</style>