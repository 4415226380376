<template>

    <div class="body">
       <div class="container" v-if="datas.length > 0">
            <div class="row">
                <div class="col-md-4 my-2" v-for="(data, index) in datas" :key="index">
                    <div class="booking-card">
                        <div class="booking-id">#{{ data.ID }}</div>
                        <div class="row h-100">                    

                            <div class="col-12">
                                <div class="item-container v2">
                                    <div class="title"><i class="fas fa-shipping-fast"></i></div>
                                    <div class="item">
                                        <span v-if="data.shipping_method == 'face_to_face'">{{ $t('Face to Face') }}</span>
                                        <span v-if="data.shipping_method == 'postage'">{{ $t('Postage') }}</span>
                                    </div>
                                </div>  
                            </div> 
                        
                            <div class="col-md-6">
                                <div class="item-container v2">
                                    <div class="title"><i class="fas fa-yen-sign"></i></div>
                                    <div class="value item">{{ data.budget }} {{ $t('Yen') }}</div>
                                </div>
                            </div>
                        
                            <div class="col-6">
                                <div class="item-container v2">
                                    <div class="title"><i class="fas fa-calendar-day"></i></div>
                                    <div class="value item">{{ new Date(data.date_time).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) }}</div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="item-container v2">
                                    <div class="title"><i class="fas fa-clock"></i></div>
                                    <div class="value item">{{ new Date(data.date_time).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })  }}</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="item-container v2 align-items-baseline">
                                    <div class="title"><i class="fas fa-map-marker-alt"></i></div>
                                    <div class="item">
                                        {{ data.zipcode + " " + data.country + " " + data.state + " " + data.city + " " + data.street + " " + data.building }}
                                    </div>
                                </div>     
                            </div>                  

                            

                            <div class="col-12 mt-auto">
                                <div class="button-container">
                                    <button type="button" class="button" @click="toggleCounterOfferModal(data.ID)">
                                        <i class="fas fa-sync"></i>
                                    </button>                    
                                    <button type="submit" class="button" @click="toggleDetailModal(data.ID)">
                                        <i class="fas fa-info"></i>
                                    </button>                    
                                    <button type="button" class="button" v-if="parseInt(data.suggestion) == 0" @click="acceptOffer(data.ID)">
                                        <i class="fas fa-check"></i>
                                    </button>                    
                                </div>
                            </div>

                        </div> 
                    </div> 
                </div>
            </div>       
       </div>
       
       <div class="container" v-else>
            No Booking Available Currently
       </div>

       <CustomModal :isToggle="isOfferToggle" @toggleModal="untoggleOffer">
            <div class="container">
                <div class="title my-4">Counter Offer</div>
                <input type="number" name="counter_offer" id="counter_offer">
                <div class="button-container">
                    <button type="button" class="button close-button">
                        <i class="far fa-trash-alt"></i>
                    </button>
                    <button type="button" class="button close-button">
                        <i class="fas fa-check"></i>
                    </button>
                </div>
            </div>
       </CustomModal>


       <CustomModal :isToggle="isToggle" @toggleModal="setModalToFalse">
            <div class="container">

                <CounterOfferModal v-if="counterOfferModal" :key="detailModalKey"/>
                <DetailModal v-if="detailModal" :key="detailModalKey" />
            </div>
        </CustomModal>
    </div>

</template>

<script>


import axios from 'axios';
import CustomModal from '@/components/custom-modal/custom-modal.component.vue'
import CounterOfferModal from '@/components/counter-offer-modal/counter-offer.component.vue'
import Button from '@/components/button/button.component.vue'
import DetailModal from '@/components/detail-modal/detail.component.vue'
import store from "../../store";
export default {
    components: {
        CustomModal,  
        Button,  
        CounterOfferModal,
        DetailModal
    },
    data() {
        return {
            datas:[],
            isOfferToggle: false,
            isDetailToggle: false,
            isToggle: false,
            detailModal:false,
            counterOfferModal: false,
            modalHeader: "",
            detailModalKey:""
        };
    },
    methods: {
        getBooking(){
            this.url = this.HostUrl + "booking/qers-get-all-bookings"
            axios.get(this.url)
            .then(response => {
                this.datas = response.data['bookings'];
                console.log(this.datas)
            })
            .catch(function (error) {
                console.log(JSON.stringify(error));
                console.log("error response :" + error.response)
                console.log("error status :" + error.status)
            });
        },
       
        resetBookingID() {
            store.commit("BookingID", null);
        },
        toggleDetailModal(booking_id){

            store.commit("BookingID", booking_id);
            this.detailModal = true;
            this.counterOfferModal = false;
            this.isToggle = true;
            this.modalHeader = "Booking Details";
            this.detailModalKey = booking_id;
            document.body.style.overflow = "hidden";
        },
        toggleCounterOfferModal(booking_id){
            store.commit("BookingID", booking_id);
            this.counterOfferModal = true;
            this.detailModal = false;
            this.isToggle = true;
            this.modalHeader = "Counter Offer";
            this.detailModalKey = booking_id;
            document.body.style.overflow = "hidden";
        },
        setModalToFalse() {
     
            this.isToggle = false;
            this.resetBookingID();
        },
        acceptOffer(booking_id){
            this.url = this.HostUrl + "booking/accept-booking";
            console.log(this.UserID)
            axios.post(this.url, {
                qers: this.UserID,
                booking_id: booking_id,
                username: this.Username,
                
            }).then(response => {
                              
                this.$router.push("/manage-bookings");
               
            })
            .catch(function () {
                console.log('FAILURE!!');
            });
        }
    },    
    mounted(){
    },
    created() {
        this.getBooking()
    },

    computed: {
        HostUrl() {
            return this.$store.state.host_url;
        },
        Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        datePart(dt) {
            const date = new Date(dt);
            return date.toLocaleDateString();
        },
        timePart(dt) {
            const date = new Date(dt);
            return date.toLocaleTimeString();
        },
    }
};
</script>
<style lang="scss" scoped>
    @import '@/assets/css/main.scss';
    @import './booking.styles.scss';
    #remark::placeholder {
        color: lightgrey;
    }
</style>