<template>

    <div class="body">
        <div class="container">
            <div class="title mb-4">{{ $t('Manage Booking') }}</div>
            <div class="row">
                <div v-if="datas.length > 0">
                    <div class="col-md-12" v-for="(data, index) in datas" :key="index">
                        <div class="form-container">
                            <div class="id-container">
                                <div class="title">{{ data.ID }}</div>
                            </div>                      
                            <div class="row">
                                <div v-if="data.suggestion == '0'" v-for="(item, item_index) in data.items" :key="item_index">
                                    <div class="col-12">
                                        <div class="product-container">
                                            <div class="image-container" @click="toggleImage(item.image_path)" v-if="item.image_path !== 'no image'">
                                                <img :src="item.image_path" class="image" loading="lazy" draggable="false">
                                            </div>
                                            <div v-else>
                                                No image
                                            </div>
                                            <div class="product-detail">
                                                <div class="item-container">
                                                    <div class="title">{{ $t('Item Name') }}</div>
                                                    <div class="value">{{ item.name }}</div>
                                                </div>
                                                <div class="item-container">
                                                    <div class="title">{{ $t('Price') }}</div>
                                                    <div class="value">{{ item.price }}</div>
                                                </div>     
                                            </div>                               
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="col-md-12">
                                    <div class="item-container">
                                        <div class="title">{{ $t('Item Category') }}</div>
                                        <div class="value">  {{ getTranslatedCategories(data.categories) }} </div>
                                    </div>
                                </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="item-container">
                                        <div class="title">{{ $t('Date') }}</div>
                                        <div class="value">{{ new Date(data.date_time).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="item-container">
                                        <div class="title">{{ $t('Time') }}</div>
                                        <div class="value">{{ new Date(data.date_time).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })  }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="item-container">
                                        <div class="title">{{ $t('Contact Number') }}</div>
                                        <div class="value">{{ data.phone }}</div>                                
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="item-container">
                                        <div class="title">{{ $t('Shipping Method') }}</div>
                                        <div class="value">
                                            <span v-if="data.shipping_method == 'face_to_face'">{{ $t('Face to Face') }}</span>
                                            <span v-if="data.shipping_method == 'postage'">{{ $t('Postage') }}</span>
                                        </div>                                
                                    </div>
                                </div>
                                <label class="mb-2">{{ $t('Address') }}</label>
                                <div class="col-md-12">
                                    <div class="item-container" v-if="data.zipcode">
                                        <div class="title">{{ $t('Zipcode') }}</div>
                                        <div class="value">{{ data.zipcode }}</div>
                                    </div>
                                
                                    <div class="item-container" v-if="data.country">
                                        <div class="title">{{ $t('Country') }}</div>
                                        <div class="value">{{ data.country }}</div>
                                    </div>
                                    <div class="item-container" v-if="data.state">
                                        <div class="title">{{ $t('State') }}</div>
                                        <div class="value">{{ data.state }}</div>
                                    </div>
                                    <div class="item-container" v-if="data.city">
                                        <div class="title">{{ $t('City') }}</div>
                                        <div class="value">{{ data.city }}</div>
                                    </div>
                                    <div class="item-container" v-if="data.street">
                                        <div class="title">{{ $t('Street') }}</div>
                                        <div class="value">{{ data.street }}</div>
                                    </div>
                                    <div class="item-container" v-if="data.building">
                                        <div class="title">{{ $t('Building') }}</div>
                                        <div class="value">{{ data.building }}</div>
                                    </div>
                                </div>
                             
                                <div class="col-md-6">
                                    <div class="item-container">
                                        <div class="title">{{ $t('Budget') }}</div>
                                        <div class="value">{{ data.budget }} {{ $t('Yen') }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="item-container">
                                        <div class="title">{{ $t('Qers Offer') }}</div>
                                        <div class="value">
                                            <span v-if="data.price">{{ data.price }}</span>
                                            <span v-else>
                                                <span v-if="data.status == 'cancelled'">{{ $t('cancelled') }}</span>
                                                <span v-else>{{ $t('Waiting for Qers ...') }} </span>
                                             </span>   
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="item-container">
                                        <div class="title">{{ $t('Status') }}</div>
                                        <div class="value">
                                            <span v-if="data.status == 'waiting_for_qers_to_accept'">{{ $t('waiting_for_qers_to_accept') }}</span>
                                            <span v-if="data.status == 'qers_accepted'">{{ $t('qers_accepted') }}</span>
                                            <span v-if="data.status == 'payment_paid'">{{ $t('payment_paid') }}</span>
                                            <span v-if="data.status == 'completed'">{{ $t('completed') }}</span>
                                            <span v-if="data.status == 'cancelled'">{{ $t('cancelled') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="button-container">
                                <button type="button" class="button" @click="goToChatroom(data.ID)">
                                    <i class="fas fa-comments"></i>
                                </button>      
                                <button type="button" class="button" @click="startRecord(data.ID)" v-if="data.status == 'payment_paid'">
                                    <i class="fas fa-shopping-cart"></i>
                                </button>              
                                <button type="button" class="button" @click="completeBooking(data.ID)" v-if="data.status == 'qers_start_queue'">
                                    <i class="fas fa-clipboard-check"></i>
                                </button>  
                                <button type="button" class="button" @click="editPrice(data.ID)" v-if="data.status != 'completed'">
                                    <i class="fas fa-edit"></i>
                                </button>  
                            </div>
                        </div>          
                    </div>     
                </div> 
                <div class="no-booking" v-else>
                    <div>No booking available.</div>              
                    <div class="button">
                        <div>Start</div>
                        <button @click="navigate_to_booking">ACCEPT BOOKING HERE.</button>
                    </div>         
                </div>   
                <CustomModal :isToggle="ModalStatus" @toggleModal="setModalToFalse">
                    <div class="container">
                        <CounterOfferModal :key="detailModalKey"/>
                    </div>
                </CustomModal>                              
            </div>
        </div>     
    </div>
    <CustomModal :isToggle="isImageToggle" @toggleModal="setImageModaltoFalse">
        <img :src="image_url" class="modal-image" loading="lazy" draggable=false width="500" height="309">
    </CustomModal>

</template>

<script>
import Button from '@/components/button/button.component.vue'
import CustomModal from '@/components/custom-modal/custom-modal.component.vue'
import axios from 'axios';
import store from "../../store";
import CounterOfferModal from '@/components/counter-offer-modal/counter-offer.component.vue'
export default {
    components: {
        Button,    
        CustomModal,
        CounterOfferModal,
    },
    data() {
        return {
           url:"",
           datas:[],
           isToggle: false,
           isImageToggle:false,
           image_url: "",
           detailModalKey:""
        };
    },
    methods: {
        async getBookings() {
            this.url = this.HostUrl + "booking/get-bookings";
            try {
                const response = await axios.post(this.url, {
                    qers: this.UserID,
                });

                this.datas = response.data["bookings"];
                console.log(this.datas);
            } catch (error) {
                console.log(JSON.stringify(error));
                console.log("error response :" + error.response);
                console.log("error status :" + error.status);
            }
        },
        navigate_to_booking(){
            this.$router.push({ name: 'BookingList' });
        },
        toggleImage(image_link) {
            this.isImageToggle = true;
            this.image_url = image_link;
        },
        setModalToFalse() {
            store.commit("setModalVisible", false);
            
        },
        setImageModaltoFalse(){
            this.isImageToggle = false;
        },
        getTranslatedCategories(categoryValuesString) {
            const categoryTranslations = {
                food_snacks: this.$t('Food/Snacks'),
                keychain: this.$t('Keychain'),
                cosmetics: this.$t('Cosmetics'),
                medicine: this.$t('Medicine'),
                fashion: this.$t('Fashion'),
                limited_goods: this.$t('Limited Goods'),
                idol_anime_merchandise: this.$t('Idol/Anime Merchandise'),
            };

            const categoryValues = categoryValuesString.split(';');
            const translatedCategories = categoryValues.map(categoryValue => categoryTranslations[categoryValue]);

            return translatedCategories.join(', ');
        },
        
        goToChatroom(booking_id){
            store.commit("BookingID", booking_id);
            this.$router.push('/chat/'+booking_id);
        },
        editPrice(booking_id){
            store.commit("BookingID", booking_id);
            store.commit("setModalVisible", true);
            this.isToggle = true;
            this.modalHeader = "Counter Offer";
            this.detailModalKey = booking_id;
            document.body.style.overflow = "hidden";
            
        },
        async startRecord(booking_id){
            this.url = this.HostUrl + "booking/start-queue";
            try {
                const response = await axios.post(this.url, {
                    qers: this.UserID,
                    username: this.Username
                });

                this.datas = response.data["bookings"];
                console.log(this.datas);
            } catch (error) {
                console.log(JSON.stringify(error));
                console.log("error response :" + error.response);
                console.log("error status :" + error.status);
            }
        },
        async completeBooking(booking_id){
            this.url = this.HostUrl + "booking/complete-booking";
            try {
                const response = await axios.post(this.url, {
                    qers: this.UserID,
                    username: this.Username
                });

                this.datas = response.data["bookings"];
                console.log(this.datas);
            } catch (error) {
                console.log(JSON.stringify(error));
                console.log("error response :" + error.response);
                console.log("error status :" + error.status);
            }
        }

        

    },
        

    
    created() {
       this.getBookings()
    },

    computed: {
        HostUrl() {
            return this.$store.state.host_url;
        },
        Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        datePart(dt) {
            const date = new Date(dt);
            return date.toLocaleDateString();
        },
        timePart(dt) {
            const date = new Date(dt);
            return date.toLocaleTimeString();
        },
        BookingID(){
            return this.$store.state.booking_id;
        },
        ModalStatus(){
            return this.$store.state.modalVisible;
        }
    },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/css/main.scss';
    @import './manage-bookings.styles.scss';
</style>