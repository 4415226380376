<template>
 <div class="container">
    <div class="title my-4">Counter Offer for #{{ BookingID }}</div>
    <input type="number" name="price" id="price" v-model="price">
    <div class="button-container mt-5">        
        <button type="button" class="button close-button" @click="sendPrice">
            <i class="fas fa-check"></i>
        </button>
    </div>
</div>
</template>

<script>


import axios from 'axios';
import CustomModal from '@/components/custom-modal/custom-modal.component.vue'
import store from "../../store";
export default {
    components: {
        CustomModal,  
     
    },
    data() {
        return {
          url:"",
          price:""
        };
    },
    methods: {
        sendPrice(){
            console.log("counter offer is done") 
            this.url = this.HostUrl + "booking/counter-offer";
            if(this.price != ""){
                axios.post(this.url, {
                    qers: this.UserID,
                    booking_id: this.BookingID,
                    username: this.Username,
                    offer:this.price
                }).then(response => {
                    console.log(this.$route.path)
                    console.log(response.data)
                    if (this.$route.path === "/manage-bookings") {
                        store.commit("setModalVisible", false);
                        window.location.reload();
                    }
                    else{
                        this.$router.push("/manage-bookings");
                    }
                    
                })
                .catch(function () {
                    console.log('FAILURE!!');
                });
            }
            else{
                alert("Please insert price")
            }
           
        },
    
    },    
    mounted(){
        
    },
    created() {

    },

    computed: {
        HostUrl() {
            return this.$store.state.host_url;
        },
        Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        BookingID(){
            return this.$store.state.booking_id;
        },

    }
};
</script>
<style lang="scss" scoped>
    @import '@/assets/css/main.scss';
    @import './counter-offer.styles.scss';
</style>