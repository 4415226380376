<template>

    <div class="body">        
        <div class="container">
            <form @submit.prevent="verify">
                <div class="login-container">
                    <div class="login-content">
                        <div class="title mb-4">{{ $t('Register') }}</div>
                        <div class="input-container md:w-3/4 mx-auto mb-8">
                            <input type="text" :placeholder="$t('Name')" id="name" name="name" v-model="name">
                        </div>
                        <div class="input-container md:w-3/4 mx-auto mb-8">
                            <input type="email" :placeholder="$t('Email')" id="email" name="email" v-model="email">
                        </div>                    
                        <div class="input-container md:w-3/4 mx-auto mb-8">
                            <input type="password" :placeholder="$t('Password')" id="password" name="password" v-model="password">
                        </div>
                        <div class="input-container md:w-3/4 mx-auto mb-8">
                            <input type="password" :placeholder="$t('Confirm Password')" id="confirm_password" name="confirm_password" v-model="confirm_password">
                        </div>
                        <div class="input-container md:w-3/4 mx-auto mb-8">
                            <input type="text" :placeholder="$t('Contact Number')" id="phone" name="phone" v-model="phone">
                        </div>
                        <div class="input-container md:w-3/4 mx-auto mb-8 text-start tnc-checkbox">
                            <label for="tnc">
                                <input type="checkbox" name="tnc" id="tnc" v-model="tnc">
                            </label>
                            <div>
                                {{$t('By checking this box, you are agreeing to our') }} <a class="tnc-modal" @click="toggleTncModal()">  {{$t('Terms and Conditions') }}</a> 
                            </div>                     
                        </div>

                        <div class="mt-12 mb-6 login-button">                        
                            <Button type="submit" :title="$t('Register')" />
                        </div>
                    </div>
                </div>
            </form>     
        </div>       
    </div>
    <span class="tnc-modal-container">
        <CustomModal id="tnc-modal" :isToggle="isToggle" @toggleModal="setModalToFalse">
            <div class="container">
            <h1>{{ modalHeader }}</h1>
            <TncContent v-for="(item, num) in modalBody" :key="num" :DATA="item" />
            </div>
        </CustomModal>
    </span>

</template>

<script>

import Button from '@/components/button/button.component.vue'
import CustomModal from '@/components/custom-modal/custom-modal.component.vue'
import TncContent from '@/components/tnc-content/tnc-content.component.vue'

import DATA_EN from '@/assets/data/tnc/tnc-en.json'
import DATA_CN from '@/assets/data/tnc/tnc-cn.json'
import DATA_JP from '@/assets/data/tnc/tnc-jp.json'

import axios from 'axios';
export default {
    components: {
        Button,       
        CustomModal,
        TncContent,
    },
    data() {
        return {
            email: "",
            password: "",
            confirm_password: "",
            name: "",
            phone: "",
            tnc: true,
            isToggle: false,
            DATA_EN,
            isToggleCn: false,
            DATA_CN,
            isToggleJp: false,
            DATA_JP,
            currentLanguage: 'en'
        };
    },
    methods: {
        verify() {
            this.feedback = ""
            
            if(this.tnc == false) {
                this.feedback = "You must agree carpu terms and conditions"
            }
            if (this.email == "") {
                this.feedback = "You must enter an email";
                return;
            }
            if (this.password == "") {
                this.feedback = "You must enter a password";
                return;
            }
            if (this.confirm_password == "") {
                this.feedback = "You must enter a confirm password";
                return;
            }
            if (this.password != this.confirm_password) {
                this.feedback = "The confirm password must be the same as the password"
                return;
            }
            if (this.name == "") {
                this.feedback = "You must enter a name";
                return;
            }
            if (this.phone == "") {
                this.feedback = "You must enter a phone number";
                return;
            }
            
            this.register();
        },
        register() {

            this.url = this.HostUrl + "qers/register";
         
            axios.post(this.url, {
                password_confirmation: this.confirm_password,
                email: this.email,
                name: this.name,
                password: this.password,
                phone: this.phone,
                 
            }).then(response => {
                this.show = false;
                console.log(response.data)
                if (response.data['status'] == 200) {
                    this.$router.push("/login");
                } else {
                    alert("Email existed in database");
                }
            })
            .catch(function () {
                console.log('FAILURE!!');
            });
        },
        getCurrentLanguage() {
            this.currentLanguage = this.$i18n.locale;
            console.log(this.currentLanguage)
        },
        toggleTncModal() {
            this.getCurrentLanguage();
            this.isToggle = true;
            document.body.style.overflow = "hidden";
        },
        setModalToFalse() {
            this.isToggle = false;
        },
    },
    
    mounted() {
    },

    
    created() {
      

    },

    computed: {
        HostUrl() {
            return this.$store.state.host_url;
        },
        modalHeader() {
            if (this.currentLanguage === 'en') {
                return this.DATA_EN.header;
            } else if (this.currentLanguage === 'cn') {
                return this.DATA_CN.header;
            } else if (this.currentLanguage === 'jp') {
                return this.DATA_JP.header;
            }
        },
        modalBody() {
            if (this.currentLanguage === 'en') {
                return this.DATA_EN.body;
            } else if (this.currentLanguage === 'cn') {
                return this.DATA_CN.body;
            } else if (this.currentLanguage === 'jp') {
                return this.DATA_JP.body;
            }
        },
    }
};
</script>
<style lang="scss" scoped>
    @import '@/assets/css/main.scss';
    @import './register.styles.scss';
</style>