<template>
    <Navbar />
    <div class="body">
        <div class="container">
            <div class="title">My profile</div>
            <div class="row mx-0">
                <div class="col-md-2 mt-4">
                    <div class="profile-picture ms-md-0 me-md-auto mx-auto">
                        <img src="@/assets/images/misc/profile-default.jpg" alt="profile" height="100" width="100" loading="lazy" draggable="false">
                    </div>                    
                </div>
                <div class="col-md-10 my-4">
                    <div class="form-container">
                        <form action="">

                            <div class="row">

                                <div class="col-md-6">
                                    <div class="input-container">
                                        <label for="name">Full Name</label>
                                        <input type="text" id="name" name="name" value="name">
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="input-container">
                                        <label for="password">Password</label>
                                        <input type="password" placeholder="Password" name="password" value="password">
                                        <div class="show-password"><i class="bi bi-eye-fill"></i></div>
                                    </div>
                                </div>
                                
                                <div class="col-md-6">
                                    <div class="input-container">
                                        <label for="phone">Mobile Number</label>
                                        <input type="tel" id="phone" name="phone" value="0000000">
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="input-container">
                                        <label for="email">Email</label>
                                        <input type="email" id="email" name="email" value="email@mail.com" disabled>
                                    </div>
                                </div>

                                <div class="col-12 my-4">
                                    <div class="md:w-1/4 me-auto">
                                        <Button type="submit" title="Save Changes" ButtonStyle="color-fill" />
                                    </div>
                                </div>

                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>     
    </div>
    <Footer />
</template>

<script>

import Navbar from '@/components/navbar/navbar.component.vue'
import Footer from '@/components/footer/footer.component.vue'
import Button from '@/components/button/button.component.vue'

export default {
    components: {
        Navbar,
        Footer,
        Button,  
    },
    data() {
        return {
           
        };
    },
    methods: {

    },
        

    
    created() {
       

    },

    computed: {
       
    }
};
</script>

<style lang="scss" scoped>
    @import '@/assets/css/main.scss';
    @import './profile.styles.scss';
</style>