<template>
    <div class="container">
        <div class="row">
            <div class="booking-id title">#{{ data.ID }}</div>
            <div class="row h-100">         
                <div v-if="parseInt(data.suggestion) == 1">
                    <div class="col-md-12" v-if="data.categories">
                        <div class="item-container">
                            <div class="title">{{ $t('Item Category') }}</div>
                            <div class="value">  {{ getTranslatedCategories(data.categories) }} </div>
                        </div>
                    </div>
                </div>
                <div v-else v-for="(item, item_index) in data.items" :key="item_index">
                    <div class="col-12">
                        <div class="product-container">
                            <div class="image-container" @click="toggleImage" v-if="item.image_path !== 'no image'">
                                <img :src="item.image_path" class="image" loading="lazy" draggable="false">
                            </div>
                            <div v-else>
                                No image
                            </div>
                            <div class="product-detail">
                                <div class="item-container">
                                    <div class="title">{{ $t('Item Name') }}</div>
                                    <div class="value">{{ item.name }}</div>
                                </div>
                                <div class="item-container">
                                    <div class="title">{{ $t('Price') }}</div>
                                    <div class="value">{{ item.price }}</div>
                                </div>     
                            </div>                               
                        </div>
                    </div>
                </div>
                
                 

                <div class="col-md-6">
                    <div class="item-container v2">
                        <div class="title"><i class="fas fa-yen-sign"></i></div>
                        <div class="value item">{{ data.budget }} {{ $t('Yen') }}</div>
                    </div>
                </div>     


                <div class="col-md-6">
                    <div class="item-container v2">
                        <div class="title"><i class="fas fa-phone-alt"></i></div>
                        <div class="item">
                            {{ data.phone }}
                        </div>
                    </div>  
                </div> 

                <div class="col-md-6">
                    <div class="item-container v2">
                        <div class="title"><i class="fas fa-shipping-fast"></i></div>
                        <div class="item">
                            <span v-if="data.shipping_method == 'face_to_face'">{{ $t('Face to Face') }}</span>
                            <span v-if="data.shipping_method == 'postage'">{{ $t('Postage') }}</span>
                        </div>
                    </div>  
                </div> 
            
                <div class="col-6">
                    <div class="item-container v2">
                        <div class="title"><i class="fas fa-calendar-day"></i></div>
                        <div class="value item">{{ new Date(data.date_time).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) }}</div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="item-container v2">
                        <div class="title"><i class="fas fa-clock"></i></div>
                        <div class="value item">{{ new Date(data.date_time).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })  }}</div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="item-container v2">
                        <div class="title"><i class="fas fa-map-marker-alt"></i></div>
                        <div class="item">
                            {{ data.zipcode + " " + data.country + " " + data.state + " " + data.city + " " + data.street + " " + data.building }}
                        </div>
                    </div>     
                </div>                  
            </div>
        </div>
    </div>   
     
  </template>
  
  <script>
  import axios from 'axios';
  import Button from '@/components/button/button.component.vue'
  export default {
    components: {
        Button,    

    },
    data() {
        return {
           url:"",
           data:{},
           isToggle: false,
        };
    },
    methods: {
        async getBookings() {
            this.url = this.HostUrl + "booking/get-booking";
            console.log("get bookings : " + this.BookingID);
            try {
                var response = await axios.post(this.url, {
                    booking_id: this.BookingID,
                });

                this.data = response.data["booking"];
               
            } catch (error) {
                console.log(JSON.stringify(error));
                console.log("error response :" + error.response);
                console.log("error status :" + error.status);
            }
        },
        
        getTranslatedCategories(categoryValuesString) {
            const categoryTranslations = {
                food_snacks: this.$t('Food/Snacks'),
                keychain: this.$t('Keychain'),
                cosmetics: this.$t('Cosmetics'),
                medicine: this.$t('Medicine'),
                fashion: this.$t('Fashion'),
                limited_goods: this.$t('Limited Goods'),
                idol_anime_merchandise: this.$t('Idol/Anime Merchandise'),
                anime_manga: this.$t('Anime/Manga'), 
                traditional_japanese_art_and_culture: this. $t('Traditional Japanese Art and Culture'),
                food_and_drink: this.$t('Food and Drink'),
                medicine_supplements: this.$t('Medicine/Supplements'),
                fashion_accessiories: this.$t('Fashion/Accessories'),
                technology_gadgets: this.$t('Technology/Gadgets'),
                nature_outdoors: this.$t('Nature/Outdoors')
            };

            const categoryValues = categoryValuesString.split(';');
            const translatedCategories = categoryValues.map(categoryValue => categoryTranslations[categoryValue]);

            return translatedCategories.join(', ');
        },
        

    },
        

    
    created() {
       this.getBookings()
    },

    computed: {
        HostUrl() {
            return this.$store.state.host_url;
        },
        Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        datePart(dt) {
            const date = new Date(dt);
            return date.toLocaleDateString();
        },
        timePart(dt) {
            const date = new Date(dt);
            return date.toLocaleTimeString();
        },
        BookingID(){
            return this.$store.state.booking_id;
        },
    },
};
  </script>
  
  <style lang="scss" scoped>
    @import '@/assets/css/main.scss';
  </style>