import { createRouter, createWebHistory } from 'vue-router'

import store from '../store' 

import BookingList from '@/views/booking/BookingList'
import LoginView from '@/views/login/LoginView'
import ProfileView from '@/views/profile/Profile'
import RegisterView from '@/views/register/Register'
import ManageBookings from '@/views/manage-bookings/ManageBookings'
import ForgetPassword from '@/views/forget-password/ForgetPassword'
import ChatRoom from '@/views/chatroom/ChatRoom'

const routes = [
  {
    path: '/',
    name: 'BookingList',
    component: BookingList,
    meta: {
      requiresAuth: true
    }
  },
  
  {
    path: '/manage-bookings',
    name: 'ManageBookings',
    component: ManageBookings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/chat/:booking_id',
    name: 'ChatRoom',
    component: ChatRoom,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileView,
    meta: {
      requiresAuth: true
    }
  
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: ForgetPassword,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.is_login) {
      next({ name: 'Login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})
export default router